import React from 'react'
import Layout from '../../components/layout'
import { StaticQuery, graphql } from 'gatsby'

import Nav from '../../components/en/nav'

const LearnPage = () => (
  <StaticQuery
    query={graphql`
      query ENLearnQuery {
        wordpressPage(wordpress_id: { eq: 191 }) {
          acf {
            english
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Nav />
        <div className="app-main body-main">
          <h1>READY TO KNOW MORE?</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.wordpressPage.acf.english,
            }}
          />
        </div>
      </Layout>
    )}
  />
)

export default LearnPage
